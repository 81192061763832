<template>
  <v-dialog v-model="dialog" max-width="1200" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Preview Faktur Pajak</span>
      </v-card-title>
      <v-card-text>
        <iframe
          :src="url"
          frameborder="0"
          type="application/pdf"
          width="100%"
          height="600px"
        ></iframe>
      </v-card-text>
      <v-card-actions>
        <v-spacer>
          <v-btn color="error" @click="close" class="mr-2">
            Close
          </v-btn>
          <v-btn color="success" @click="save">
            Export CSV
          </v-btn>
        </v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import "jspdf-autotable";
import { jsPDF } from "jspdf";

export default {
  name: "preview-faktur-pajak",
  props: {
    dialog: Boolean,
    item: Object,
    dp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: "",
    };
  },
  computed: {
    total() {
      let total = 0;
      this.item.products.forEach(x => {
        total += x.total;
      });
      return total;
    },
    date() {
      return moment().format("DD-MMMM-YYYY");
    },
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed().replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setNewArray(items) {
      const array = [];
      items.forEach((x, index) => {
        array.push([
          {
            content: index + 1,
            styles: { valign: "top", halign: "center" },
          },
          {
            content: `PN:${x.partNumber};Desc:${x.partDesc}`,
            styles: { valign: "top", halign: "left" },
          },
          {
            content: this.formatPrice(x.total),
            styles: { valign: "top", halign: "right" },
          },
        ]);
      });
      return array;
    },
    percentage() {
      if (this.dp) {
        return `UANG MUKA ${this.item.percentageDownPayment}%`;
      } else {
        return `PELUNASAN ${this.item.percentagePaid}%`;
      }
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text(this.item.taxInvoiceSerialNumber, 90, 10);

      doc.text("PT. GRAHASUMBER PRIMA ELEKTRONIK", 50, 30);
      doc.text(
        "INTERCON PLAZA BLOK D 11, JL. MERUYA ILIR, SRENGSENG-KEMBANGAN, JAKARTA BARAT",
        50,
        35
      );
      doc.text("01.789.513.7-038.000", 50, 40);
      doc.text("07 April 2008", 50, 45);

      doc.text(this.item.customerName, 50, 55);
      doc.text(this.item.customerAddress, 50, 65);
      doc.text(this.item.npwp, 50, 78);
      doc.text(`NPPKP : ${this.item.npwp}`, 110, 78);

      doc.autoTable({
        margin: { top: 95, left: 10 },
        theme: "plain",
        head: [],
        body: [
          ...this.setNewArray(this.item.products),
          [
            {
              colSpan: 2,
              content: "SUB TOTAL :",
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.total),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          [
            {
              colSpan: 2,
              content: this.percentage(),
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.item.totalAmountAfterInvoice),
              styles: { valign: "middle", halign: "right" },
            },
          ],
          [
            {
              colSpan: 2,
              content: `PPN ${this.item.percentagePpn}%`,
              styles: { valign: "middle", halign: "right" },
            },
            {
              content: this.formatPrice(this.item.taxAmount),
              styles: { valign: "middle", halign: "right" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          lineWidth: 0,
          halign: "left",
          lineColor: "black",
          fontSize: 7,
          overflow: "linebreak",
          overflowColums: "linebreak",
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          fontStyle: "bold",
        },
        columnStyles: {
          0: { cellWidth: 10 },
          1: { cellWidth: 150 },
          2: { cellWidth: 30 },
          // etc
        },
      });

      let y = doc.lastAutoTable.finalY + 10;
      doc.text(`Jakarta, ${moment().format("DD-MMMM-YYYY")}`, 130, y);

      this.url = doc.output("datauristring");
    },
    save() {
      this.$emit("export");
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
