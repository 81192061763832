<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.customerName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice From <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-radio-group v-model="form.type" row disabled>
                  <v-radio label="PO" value="PO"></v-radio>
                  <v-radio label="DO" value="DO"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.purchaseOrderNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.type === 'DO'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>DO Customer <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.listDo"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @change="onInputInvoiceDuration"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                    @change="onInputInvoiceDuration"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Term</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDuration"
                  suffix="days"
                  @change="onInputInvoiceDuration"
                  :rules="[v => v >= 1 || 'Term must more than equals 1']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Bill To<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  disabled
                  v-model="form.billTo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bill To Contact</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.billToContact"
                  :items="contacts"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Percentage Invoice<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.percentageInvoice"
                  suffix="%"
                  @change="onInputPercentageInvoice"
                  :rules="percentageInvoiceRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPN</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.percentagePpn"
                  suffix="%"
                  @change="onInputPercentagePpn"
                  :rules="[v => v >= 0 || 'PPN must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.rate"
                  prefix="Rp."
                  :rules="[v => v >= 1 || 'Rate must more than equals 1']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0 && form.fp">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-checkbox v-model="form.fp" label="Faktur Pajak Digunggung" disabled></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0 && !form.fp">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="7" lg="7" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                {{ form.remark }}
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Material Doc #</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.materialDocumentNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Discount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span>{{ form.discount }}%</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.products"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ form.products.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.percentageUpTo }}%</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Site</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.site }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Sales</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.salesName }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>PO Number</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.purchaseOrderNumber }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>DP Percentage</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.percentageDownPayment }}%</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Sub Total</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Invoice</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterInvoice) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">PPH</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.pphAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="form.percentagePpn > 0">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.taxAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-sale-product
      :item="item"
      :index="index"
      :dialog="dialog"
      :accounts="accounts"
      @save="close"
      @deleteItem="deleteItem"
    ></dialog-sale-product>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="update"
      @close="close"
      currency="IDR"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import DialogSaleProduct from "@/components/DialogSaleProduct";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "form-sale-invoice",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-sale-product": DialogSaleProduct,
    DialogReviewJournal,
  },
  props: {
    form: Object,
    accounts: Array,
    contacts: Array,
    lastPercentage: Number,
  },
  data: () => ({
    menuInvoiceDate: false,
    dialog: false,
    dialogReview: false,
    item: {},
    index: -1,
    valid: true,
    journals: [],
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "partDesc",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "quantity",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Line Item",
        value: "lineItem",
        sortable: false,
        divider: true,
      },
      {
        text: "Part#",
        value: "partNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "DO#",
        value: "doNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Mat Doc#",
        value: "materialDocumentNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.form.products.map(key => {
        subtotal = subtotal + key.total;
      });
      return subtotal - subtotal * (this.form.discount / 100);
    },
    pphAmount() {
      let subtotal = 0;
      this.form.products.map(key => {
        subtotal = subtotal + key.pph23Amount;
      });
      return subtotal;
    },
    percentageInvoiceRules() {
      const total = this.form.percentageDownPayment;
      return [
        v => !!v || "Percentage invoice is required",
        v =>
          (v <= 100 - total && v >= 1) ||
          `$Percentage invoice must be more than 1 and less than ${100 - total}`,
      ];
    },
    totalPercentage() {
      let total = 0;
      this.form.downPaymentPercentageDtos.forEach(x => {
        total += x.percentage;
      });
      return total;
    },
    // taxSerialNumber() {
    //   return this.twoDigit + this.oneDigit + "." + this.serialNumber;
    // },
  },

  watch: {
    totalAmount(val) {
      this.form.totalAmount = val;
      if (this.form.type === "DO") {
        this.form.totalAmountAfterInvoice = parseFloat(
          ((val * this.form.percentageInvoice) / 100).toFixed(0)
        );
        this.form.taxAmount = parseFloat(
          ((this.form.totalAmountAfterInvoice * this.form.percentagePpn) / 100).toFixed(0)
        );
        // this.form.totalAmountAfterTax =
        //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
        this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
      }
    },
    pphAmount(val) {
      this.form.pphAmount = parseFloat((val * (this.form.percentageInvoice / 100)).toFixed(0));
    },
  },

  methods: {
    checkJournal() {
      if (this.form.type === "DO") {
        this.form.totalAmountAfterInvoice = parseFloat(
          ((this.totalAmount * this.form.percentageInvoice) / 100).toFixed(0)
        );
        this.form.taxAmount = parseFloat(
          ((this.form.totalAmountAfterInvoice * this.form.percentagePpn) / 100).toFixed(0)
        );
        // this.form.totalAmountAfterTax =
        //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
        this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
      }
      if (this.$refs.form.validate() && this.form.products.length > 0) {
        this.journals = [
          {
            accountName: `${this.form.customerName} ${this.form.currency}`,
            debit: this.form.totalAmountAfterTax,
            credit: 0,
          },
          {
            accountName: `UANG MUKA PENJUALAN ${this.form.currency}`,
            debit: this.form.totalDownPayment !== null ? this.form.totalDownPayment : 0,
            credit: 0,
          },
          {
            accountName: `HUTANG PPN KELUARAN`,
            debit: 0,
            credit: this.form.taxAmount,
          },
        ];
        let map = this.form.products.map(x => x.accountName);
        let array = map.filter((v, i, a) => a.indexOf(v) === i);
        array.forEach(y => {
          let total = 0;
          this.form.products.forEach(x => {
            if (x.accountName === y) {
              total += x.total * (this.form.percentageInvoice / 100);
            }
          });
          this.journals.push({
            accountName: `${y}`,
            debit: 0,
            credit: total,
          });
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    update() {
      if (this.$refs.form.validate() && this.form.products.length > 0) {
        this.form.taxInvoiceSerialNumber = this.taxSerialNumber;
        if (this.isJournalBalance() === 0) {
          this.$store.commit("SET_LOADER", true);
          this.$store
            .dispatch("saleInvoice/update", this.form)
            .then(() => this.$store.commit("SET_LOADER", false))
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = this.isJournalBalance();
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Credit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    isJournalBalance() {
      let dp = this.form.totalDownPayment !== null ? this.form.totalDownPayment : 0;
      let totalDebit = dp + this.form.totalAmountAfterTax;
      let totalCredit = this.form.taxAmount + this.totalAmount;

      // this.form.products.forEach(x => {
      //   totalCredit = totalCredit + x.total * (this.form.percentageInvoice / 100);
      // });

      return totalDebit - totalCredit;
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration() {
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    onInputPercentageInvoice() {
      this.form.totalAmountAfterInvoice = parseFloat(
        (this.totalAmount * (this.form.percentageInvoice / 100)).toFixed(0)
      );
      this.form.taxAmount = Math.floor(
        parseFloat((this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100)).toFixed(2))
      );
      // this.form.totalAmountAfterTax =
      //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
      this.form.percentagePaid = (this.totalAmount / this.totalPoAmount) * 100;
      this.form.percentageUpTo =
        this.lastPercentage + this.form.percentageDownPayment + this.form.percentagePaid;
      this.items.forEach(x => {
        x.pph23Amount = x.pph23Amount * (this.form.percentageInvoice / 100);
      });
    },
    onInputPercentagePpn() {
      this.form.taxAmount = parseFloat(
        this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100).toFixed(0)
      );
      // this.form.totalAmountAfterTax =
      //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.form.products.map(x => x).indexOf(item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogReview = false;
    },
    deleteItem(index) {
      if (index > -1) {
        this.form.products.splice(index, 1);
        this.dialog = false;
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  // mounted() {
  //   this.twoDigit = this.form.taxInvoiceSerialNumber.substring(0, 2);
  //   this.oneDigit = parseInt(this.form.taxInvoiceSerialNumber.substring(2, 3));
  //   this.serialNumber = this.form.taxInvoiceSerialNumber.substring(
  //     4,
  //     this.form.taxInvoiceSerialNumber.length
  //   );
  // },
};
</script>

<style></style>
