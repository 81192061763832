<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Cancel Invoice# {{ invoiceNumber }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="red--text text-uppercase font-weight-bold">PERINGATAN !</div>
            <div>
              Pembabatalan pada invoice# <span class="font-weight-bold">{{ invoiceNumber }}</span>
              akan menghapus seluruh journal yang sudah di buat.
              <br />
              <br />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" @click="cancelWithInvoice">
          Batalkan Dengan Invoice
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" @click="cancelWithoutInvoice">
          Batalkan Tanpa Invoice
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-cancel",
  props: {
    dialog: Boolean,
    invoiceNumber: Number,
    id: Number,
  },
  data() {
    return {
      validation: "",
    };
  },
  methods: {
    cancelWithInvoice() {
      this.$emit("cancelInvoice");
      this.$emit("close");
    },
    cancelWithoutInvoice() {
      this.$emit("cancel");
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
